<template>
  <div class="animated fadeIn">
    <CModal v-model:visible="showModal" size="lg" @close="() => $emit('cancelled',false)">

      <CModalHeader class="bg-primary text-light">
        <CModalTitle>{{ title }}</CModalTitle>
      </CModalHeader>

      <CModalBody class="text-center d-block">
        <h3>Descargar {{ this.title.toLowerCase() }} </h3>
        <p>¿Quieres descargar {{ this.element }}?</p>
        <slot></slot>
      </CModalBody>

       <CModalFooter>
        <CButton @click="$emit('cancelled',false)" color="primary">Cancelar</CButton>
        <CButton class="text-light" @click="$emit('downloadItems',false)" color="primary">
          <!-- <CIcon name="cil-check-circle" />  -->
          Descargar
        </CButton>
       </CModalFooter>

    </CModal>
  </div>
</template>

<script>

export default {
  name: 'DescargarModal',
  props: {
    title: { type: String, default: 'Descargar', required: true },
    show: { type: Boolean, default: false, required: true },
    element: { type: String, default: 'Elemento', required: true},
  },
  computed: {
    showModal() {
      return this.show;
    },
  },
}
</script>
