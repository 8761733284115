import { h, resolveComponent } from 'vue'

const ListadoFacturas = () => import('@/app/views/facturas/views/ListadoFacturas');
//const DetalleExpediente = () => import('@/app/views/expedientes/views/DetalleFac');
//const NuevoExpediente = () => import('@/app/views/expedientes/views/NuevoExpediente');


const facturasRoutes = [
  {
    path: 'facturas',
    name: 'Facturas',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    children: [
      {
        path: '',
        name: 'facturas:Listado',
        component: ListadoFacturas,
        meta: {
          auth: true,
          entidad: 'facturas'
        }
      },
      // {
      //   path: 'nuevo',
      //   name: 'expedientes:Nuevo',
      //   component: NuevoExpediente,
      //   meta: {
      //     auth: true,
      //     entidad: 'expedientes'
      //   },
      // },
      // {
      //   path: ':id',
      //   name: 'expedientes:Detalle',
      //   component: DetalleExpediente,
      //   meta: {
      //     auth: true,
      //     entidad: 'expedientes'
      //   },
      // }
    ]
  }
];

export default facturasRoutes;
