<template>

  <!-- <div class="animated fadeIn"> -->

    <CRow>
      <CCol sm="12">
        <div v-if="state.files.length > 0" class="files">
          <div class="file-item" v-for="(file, index) in state.files" :key="index">
            <span>{{ file.name }}</span>
            <span class="delete-file" @click="handleClickDeleteFile(index)">Cambiar fichero</span>
          </div>
        </div>

        <div v-else class="dropzone" v-bind="getRootProps()">
          <div class="border-dropzone" :class="{ isDragActive }">
            <input v-bind="getInputProps()" />
            <p v-if="isDragActive">Suelta los ficheros...</p>
            <p v-else class="mb-0">
              <CIcon name="cil-data-transfer-up" size="md" class="me-2 text-success"/>
              Arrasta aquí tu archivo, o pincha y selecciona
            </p>

            <div v-if="isFocused" id="focus">focused</div>
            <div v-if="isDragReject" id="isDragReject">isDragReject: {{ isDragReject }}</div>
          </div>
        </div>
      </CCol>

      <CCol sm="12" class="mt-2">
        <small class="text-danger" v-for="(error, index) in errorReasons.data" :key="index" style="display: block;">
          <!-- {{  error.code }}: {{  error.message }} -->
          {{  error }}
        </small>
      </CCol>
    </CRow>

  <!-- </div> -->
</template>

<script>
import { useDropzone } from 'vue3-dropzone';
import { reactive, watch } from 'vue';
import { createBase64 } from '@/app/shared/utils/tools';
import { subirFichero } from '../global/services/fichero-service';

export default {
  name: 'DragDropInput',

  props: {
    file: { type: Object, default() { return {}}},

    // config Drag&Drop
    multiple: { type: Boolean, default: false },
    maxFiles: { type: Number, default: 0 },
    accept: { type: String, default: 'image/*,application/pdf', required: true },
    size: { type: Number, default: 23 },
    anteriorFichero: { type: Object, default() { return {}} },
    disabled: { type: Boolean, default: false },
    modeloId: { type: Number, required: true },
    modeloTipo: { type: String, required: true },
  },

  setup(props, context) {

    const preFile = reactive({ guid: ''});
    const fileUploaded = reactive({ guid: ''});


    const filesPreview = reactive({
      files: [],
    });

    const state = reactive({
      files: [],
    });

    const errorReasons = reactive({
      data: []
    });

    const apiError = reactive({
      message: '',
    });

    const options = reactive({
      multiple: props.multiple,
      onDrop,
      accept: props.accept, /// 'application/json,video/*', multiple or single 'image/*'
      disabled: props.disabled,
      maxSize: props.size,
      maxFiles: props.maxFiles
    })

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isFocused,
      isDragReject,
      // open,
      ...rest
    } = useDropzone(options)

    function onDrop(acceptFiles, rejectReasons) {
      // console.log(acceptFiles);
      // console.log('reject', rejectReasons);

      errorReasons.data = [];

      state.files = acceptFiles;
      saveFiles(acceptFiles);
      errorFiles(rejectReasons);
    }

    function localizeError(code) {

      const ERROR_TYPE = {
        'file-invalid-type': 'El formato no es el requerido', // "File type must be .pdf"
        'file-too-large': 'El tamaño del archivo no es válido' , // "File is larger than 23 bytes"
      }
      const DEFAULT = 'Archivo no válido';

      return ERROR_TYPE[code] || DEFAULT
    }

    function errorFiles(errors) {

      errors.map(item => {
        // item.errors[]   : code, message
        // item.file :File

        const messages = item.errors.map(error => {
          return localizeError(error.code);
        })

        errorReasons.data = [...messages];
      })

      if (errors.length) {
        context.emit('erroFile');
      }

    }


    async function saveFiles(files) {
    
      let temps = [];
      // (let x = 0; x < files.length; x++
      for (const file of files) {
        let attachment = {};
        attachment.name = file.name;
        attachment.size = file.size;
        attachment.result = await createBase64(file);
        filesPreview.files.push(attachment.result);

        temps = [...temps, attachment];
      }
      
    // Limpiamos el array que guarda los guid 
    context.emit('cleanFiles', 1);

      // console.log(temp,'ARCHIVO files') 
      // if (temps.length) {
        for (const temp of temps) {
          let updateFile = null;
          // console.log(props.anteriorFichero);
          const isFileNew = !!props.anteriorFichero?.guid;

          if (isFileNew) updateFile = props.anteriorFichero.guid;

          const fileToUpload = temp ;
          const dataFile = {...fileToUpload}

          subirFichero(props.modeloTipo, props.modeloId, dataFile, updateFile).then( resp => {

            if (resp.status !== 'success') throw resp.message
            dataFile.guid = resp.data;

            context.emit('saveFile', dataFile);
          }).catch( e => {
            // TODO: notificar
            console.log(e);

            if (e && e.response && e.response.data && e.response.data.message) {
              apiError.message = e.response.data.message; // Modificamos solo la propiedad "message"
            }

            context.emit('fileUploadError', apiError.message);


          }).finally(() => {

            console.log('ARCHIVO SUBIDO')
          });
      }


    };

    function handleClickDeleteFile(index) {
      state.files.splice(index, 1);
      context.emit('clearErrorMessage');
    }

    watch(state, () => {
      console.log('state', state.files);
    });

    watch(isDragActive, () => {
      console.log('isDragActive', isDragActive.value, rest);
    });

    return {
      getRootProps,
      getInputProps,
      isDragActive,
      state,
      filesPreview,
      errorReasons,
      handleClickDeleteFile,
      ...rest,
    };
  },

  data(){
    return {
      loading: false,
    }
  },
  emits:['closed', 'saveFile', 'erroFile'],
  methods: {
  },
}
</script>

<style lang="scss" scoped>


.dropzone {
  width: 100%;
  // max-width: 300px;
  margin: 0 auto;
  padding: 10px;
  border-radius: 16px; //8
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  font-size: 12px;
  line-height: 1.5;
}
.files {
  width: 100%;
  // max-width: 300px;
  margin: 0 auto;
  padding: 10px;
  border-radius: 16px; //8
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  font-size: 12px;
  line-height: 1.5;
}

.border-dropzone {
  // border: 2px dashed #ccc;
  border: 2px dashed #41b883;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: all 0.3s ease;
  background: #fff;

  &.isDragActive {
    // border: 2px dashed #ffb300;
    border: 2px dashed #ffb300;
    background: rgb(255 167 18 / 20%);
  }
}

.file-item {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(255 167 18 / 20%);
  padding: 7px;
  padding-left: 15px;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }

  .delete-file {
    background: rgba(var(--cui-primary-rgb), var(--cui-bg-opacity));
    color: #fff;
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
  }
}





</style>
