import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'bootstrap';

import { createApp } from 'vue'
import App from './App.vue'
import router from '@/app/router'
import store from './store'
import moment from 'moment';
import { Form, Field, ErrorMessage } from 'vee-validate'
import VueNotifications from '@kyvg/vue3-notification'
import { plugin } from 'vue3-cute-component';
import 'vue3-cute-component/dist/style.css';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; //Optional default CSS
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/app/components/DocsExample'

import Ziggeo from 'vue-ziggeo';
import "vue-ziggeo/dist/vue-ziggeo.css";


import FontAwesomeIcon from "@/utilities/fontawesome-icons";
import '@/utilities/validator'

//Modals
import DeleteModal from "@/app/shared/components/modals/DeleteModal";
import DescargarModal from "@/app/shared/components/modals/DescargarModal";
import AddFicherosLesion from "@/app/shared/components/modals/AddFicherosLesion";
import AddFicherosPaciente from "@/app/shared/components/modals/AddFicherosPaciente";
import NuevaLesionModal from "@/app/shared/components/modals/NuevaLesionModal";
import NuevoPacienteModal from "@/app/shared/components/modals/NuevoPacienteModal";
import ConfirmarModal from "@/app/shared/components/modals/ConfirmarModal";
import SubirFicherosModal from "@/app/shared/components/modals/SubirFicherosModal";
import EditarFicherosModal from "@/app/shared/components/modals/EditarFicherosModal";
import EditarFicherosPerfil from "@/app/shared/components/modals/EditarFicherosPerfil";
// import PersonaContactoModal from "@/app/shared/components/modals/PersonaContactoModal";
import NuevoComentarioModal from "@/app/shared/components/modals/NuevoComentarioModal";
// import VerVideoModal from '@/app/shared/components/modals/VerVideoModal';
// import CreaListaMKTModal from '@/app/shared/components/modals/CreaListaMKTModal';
// import CuotasModal from "@/app/shared/components/modals/CuotasModal";
// import LineasModal from "@/app/shared/components/modals/LineasModal";
// import CambiarGrupoModal from "@/app/shared/components/modals/CambiarGrupoModal";
import VerImagenModal from "@/app/shared/components/modals/VerImagenModal";
import VerImagenCarouselModal from "@/app/shared/components/modals/VerImagenCarouselModal";
import CardListadoFicheros2 from '@/app/shared/components/CardListadoFicheros2';

//Components
import Toast from "@/app/shared/components/Toast";
import SpinnerTtt from '@/app/shared/components/SpinnerTtt';
import CustomTimeLine from '@/app/shared/components/CustomTimeline';
import CardListadoFicheros from '@/app/shared/components/CardListadoFicheros';
import CardComentarios from '@/app/shared/components/CardComentarios';
// import ShowVideoZiggeo from '@/app/shared/components/ShowVideoZiggeo';

import DragDropInput from '@/app/shared/components/DragDropInput';
import BreadcrumpsCustom from '@/app/shared/components/BreadcrumpsCustom';
// import BadgeMartriculaStatus from '@/app/shared/components/badges/BadgeMartriculaStatus';
// import BadgeConvocatoriaStatus from '@/app/shared/components/badges/BadgeConvocatoriaStatus';
import ButtonVolverRouter from '@/app/shared/components/ButtonVolverRouter';
import CardListadoLogs from '@/app/shared/components/CardListadoLogs';



//Lists
// import PersonasList from '@/app/shared/components/lists/PersonasList'
// import CuotasList from '@/app/shared/components/lists/CuotasList'
import DireccionesList from '@/app/shared/components/lists/DireccionesList'
// import MatriculasYBecasList from '@/app/shared/components/lists/MatriculasYBecasList'
// import ConvocatoriasMatriculaList from '@/app/shared/components/lists/ConvocatoriasMatriculaList'
// import MovimientosList from '@/app/shared/components/lists/MovimientosList'
// import FacturasMatriculaList from '@/app/shared/components/lists/FacturasMatriculaList'
// import LineasList from '@/app/shared/components/lists/LineasList'




const app = createApp(App)
app.use(store)
app.use(router)

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb)
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
}

app.use(VueNotifications, options);
app.use(CoreuiVue, { key: process.env.VUE_APP_LICENCIA });
app.use(plugin);
app.use(Ziggeo);


app.provide('icons', icons)
app.component('Form', Form)
app.component('Field', Field)


app.component('SimpleTypeahead', SimpleTypeahead)
app.component("FontAwesomeIcon", FontAwesomeIcon)

app.component('VueDatePicker', VueDatePicker);

app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)
app.component('toast', Toast);
app.component('DeleteModal', DeleteModal);
app.component('DescargarModal', DescargarModal);
app.component('NuevaLesionModal', NuevaLesionModal);
app.component('AddFicherosLesion', AddFicherosLesion);
app.component('AddFicherosPaciente', AddFicherosPaciente);
app.component('ConfirmarModal', ConfirmarModal);
// app.component('CuotasList', CuotasList);
// app.component('LineasList', LineasList);
// app.component('CuotasModal', CuotasModal);
// app.component('LineasModal', LineasModal);
// app.component('CambiarGrupoModal', CambiarGrupoModal);
app.component('VerImagenModal', VerImagenModal);
app.component('VerImagenCarouselModal', VerImagenCarouselModal);
// app.component('PersonasList', PersonasList);
app.component('DireccionesList', DireccionesList);
app.component('NuevoPacienteModal', NuevoPacienteModal);
// app.component('MovimientosList', MovimientosList);
// app.component('FacturasMatriculaList', FacturasMatriculaList);
// app.component('PersonaContactoModal', PersonaContactoModal);
app.component('SubirFicherosModal', SubirFicherosModal);
app.component('EditarFicherosModal', EditarFicherosModal);
// app.component('ShowVideoZiggeo', ShowVideoZiggeo);
// app.component('BadgeMatriculaStatus', BadgeMartriculaStatus);
// app.component('BadgeConvocatoriaStatus', BadgeConvocatoriaStatus)
// app.component('MatriculasYBecasList', MatriculasYBecasList)
// app.component('VerVideoModal', VerVideoModal);
app.component('NuevoComentarioModal', NuevoComentarioModal);
app.component('EditarFicherosPerfil', EditarFicherosPerfil);
app.component('DragDropInput', DragDropInput);
app.component('SpinnerTtt', SpinnerTtt);
app.component('CustomTimeLine', CustomTimeLine);
app.component('CardListadoFicheros', CardListadoFicheros);
app.component('CardListadoFicheros2', CardListadoFicheros2);
// app.component('ConvocatoriasMatriculaList', ConvocatoriasMatriculaList);
app.component('CardComentarios', CardComentarios);
app.component('QuillEditor', QuillEditor);
app.component('BreadcrumpsCustom', BreadcrumpsCustom);
// app.component('CreaListaMKTModal', CreaListaMKTModal);
app.component('ButtonVolverRouter', ButtonVolverRouter);
app.component('CardListadoLogs', CardListadoLogs);

moment.locale('es');
window.moment = moment;

app.mount('#app')
